
import { Base64 } from 'js-base64';
import axios from 'axios';


export default {
    async initAuth({ commit, dispatch }) {
        const token = localStorage.getItem('jwtToken');

        if (token) {
          const partsOfToken = token.split('.');
          const middleString = Base64.decode(partsOfToken[1]);
          const userID = JSON.parse(middleString).uid;

          const endpoint = `api/user/${userID}`;


          const fullPayload = {
              // app: 'http://159.65.175.58/',
              // app: 'http://127.0.0.1:3333/',
              app: 'https://zamupay.com/api/',
              endpoint,
          };

          
          const user = await dispatch('retrieveUser', fullPayload);

         commit('setAccessToken', token);
         commit('setSessionData', user);


        }
    },
    async custom_headers({ state }) {
      const authToken = state.token;
  
      const param = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: authToken,
        },
      };
  
      return param;
    },

    async axiosGetRequest({ dispatch }, payload) {
      const headers = await dispatch('custom_headers');
      const { endpoint } = payload;
      const { params } = payload;
      const { app } = payload;
      const { id } = payload;
  
      let url = `${app}${endpoint}`;
      url = typeof id === 'undefined' ? url : `${url}/${id}`;
  
      const values = {
        params,
        headers: headers.headers,
      };
      // eslint-disable-next-line no-restricted-syntax
      for (const value in values) {
        if (values[value] === null || values[value] === undefined) {
          delete values[value];
        }
      }
  
      try {
        const response = await axios.get(url, values);
        return response;
      } catch (error) {
      
        return error.response.message;
      }
    },

    async retrieveUser({ dispatch }, payload) {
      try {
        const res = await dispatch('axiosGetRequest', payload, { root: true });
        return res.data;
      } catch (error) {
        return error.response;
      }
    },


};