<template>
  <div id="app">
    <v-app id="inspire">

      <AppBar />
      <MainContent />
      <Footer />
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
      MainContent: () => import('./views/layout/MainContent'),
      AppBar: () => import('./views/layout/AppBar'),
      Footer: () => import('./views/layout/Footer'),
    },
};
</script>

