
export default {
    setAccessToken(state, val) {
        state.token = val;
    },
    setSessionData(state, val) {
        state.userData = val;
    },
    setUpdateStatus(state, val) {
        state.updateStatus = val;
    },
    setPage(state, data) {
        state.page = data;
      },
    setActiveLink(state, data) {
    state.activeLink = data;
    },
      
};
