import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/pages/home'),
    meta: {
      requiresAuth: false,
    },

  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/signin'),
    meta: {
      requiresAuth: false,
    },

  },
  {
    path: '/why-us',
    name: 'why-us',
    component: () => import('@/views/pages/home'),
    meta: {
      requiresAuth: false,
    },

  },
  {
    path: '/why-us/SMEs',
    name: 'SMEs',
    component: () => import('@/views/pages/why-us/SMEs'),
    meta: {
      requiresAuth: false,
    },

  },
  {
    path: '/why-us/financial',
    name: 'financial',
    component: () => import('@/views/pages/why-us/financials'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/why-us/fintech',
    name: 'fintech',
    component: () => import('@/views/pages/why-us/fintech'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/why-us/corporate',
    name: 'corporate',
    component: () => import('@/views/pages/why-us/corporate'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/why-us/cross-border',
    name: 'cross-border',
    component: () => import('@/views/pages/why-us/cross-border'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/why-us/international',
    name: 'international',
    component: () => import('@/views/pages/why-us/international'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/why-us/saccos',
    name: 'saccos',
    component: () => import('@/views/pages/why-us/saccos'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/features',
    name: 'features',
    component: () => import('@/views/pages/features'),
    meta: {
      requiresAuth: false,
    },

  },
  {
    path: '/zamupay-messaging',
    name: 'messaging',
    component: () => import('@/views/pages/messaging'),
    meta: {
      requiresAuth: false,
    },

  },
  
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('@/views/pages/about'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/pages/support'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('@/views/pages/blog'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/careers',
    name: 'careers',
    component: () => import('@/views/pages/careers'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import('@/views/pages/support/FAQs'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/faqs/category/:id',
    name: 'faq-category',
    component: () => import('@/views/pages/support/FAQ-list'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/FAQ/:id',
    name: 'single-FAQ',
    component: () => import('@/views/pages/support/post'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/developers',
    name: 'developers',
    component: () => import('@/views/pages/developers'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/usecases',
    name: 'usecases',
    component: () => import('@/views/pages/usecases'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/pages/dashboard'),
    meta: {
      requiresAuth: false,
    },
  },
  
  {
    path: '/blog/:id',
    name: 'single-blog',
    component: () => import('@/views/pages/blog/post'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },

  },
  {
    path: '/admin/users',
    name: 'users',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },

  },
  {
    path: '/users/:id',
    name: 'single-user',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/admin/contacts',
    name: 'contacts',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/team',
    name: 'team',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/blog-admin',
    name: 'blog-admin',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/new-blog',
    name: 'new-blog',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/blog-categories',
    name: 'blog-categories',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/single-blog-categories/:id',
    name: 'single-blog-categories',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/FAQ-categories',
    name: 'FAQ-categories',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/admin/new-faq-category',
    name: 'new-faq-category',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/new-category',
    name: 'new-category',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  
  {
    path: '/admin/team/:id',
    name: 'singleTeam',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/faq-category/:id',
    name: 'singleFAQCategory',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  
  {
    path: '/admin/blog/:id',
    name: 'singleBlog',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/FAQ/:id',
    name: 'singleFAQ',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  
  {
    path: '/admin/new-FAQ',
    name: 'new-FAQ',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/FAQs',
    name: 'admin-FAQs',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/contact-sales',
    name: 'contact-sales',
    component: () => import('@/views/pages/sales'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/admin/countries',
    name: 'countries',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/new-country',
    name: 'new-country',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  
  {
    path: '/admin/country/:id',
    name: 'country',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },  
  {
    path: '/admin/payment-volumes',
    name: 'volumes',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },
  
  {
    path: '/admin/payment-volumes/:id',
    name: 'volume',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },  
  {
    path: '/admin/sales-contact/:id',
    name: 'salesContact',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },  
  {
    path: '/admin/sales-contacts',
    name: 'salesContacts',
    component: () => import('@/views/pages/admin'),
    meta: {
      requiresAuth: true,
    },
  },  
  {
    path: '/terms-conditions',
    name: 'termsConditions',
    component: () => import('@/views/pages/terms'),
    meta: {
      requiresAuth: false,
    },
  }, 
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import('@/views/pages/terms/privacy'),
    meta: {
      requiresAuth: false,
    },
  }, 
]

const router = new VueRouter({
  routes,
  mode: 'history',
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.dispatch('initAuth');
    const token = localStorage.getItem('jwtToken');

    if (token) {
      next();
      return;
    } else {
    next('/login');
    }
  } else {
    next();
  }
});

export default router
