import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

Vue.use(Vuex);
const state = {
    token: null,
    userData: null,
    updateStatus: false,
    s3Path: 'https://pilongtermbackups.s3.amazonaws.com/Zamupay/images/',
    page: 1,
    activeLink: localStorage.getItem('activeLink'),


};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
